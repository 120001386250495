import React from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PortFolio from "../components/shared/portfolio/portfolio"
import LastNews from "../components/shared/last-news/last-news"

import "../styles/games.scss"
import "../styles/game-page.scss"
import "../styles/main-page.scss"

// Videos
import main_video_cover from "../videos/main-cover.jpg"
import main_video from "../videos/output.mp4"

class HomePage extends React.Component {

  render(){

    return(
      <>
        
        <Helmet>
          <body className="home-page"/>
        </Helmet>

        <Layout>

          <SEO title="Home"/>
          <h1 className="logo">Super!com</h1>
          

          <div className="main-page">

            <div className="scroll-arrow"></div>
          
            <section className="main-page__hero">

              <div className="main-page__hero__video">
                <video poster={main_video_cover} muted="muted" preload="auto" loop="loop" playsInline="playsinline" autoPlay="autoplay">
                  <source src={main_video} type="video/mp4"/>
                </video>
              </div>

              <div className="center">

                <h2 className="main-page__hero__title -hidden">
                  <span className="main-page__hero__title__link">Create</span><span className="main-page__hero__title__outlined-text">,</span><br className="main-page__hero__title__br"/> Develop, Execute
                </h2>
                {/* <p className="main-page__hero__message">
                  Work hand in hand with super to move your project forward.<br/>
                  You control the game, we give you the resources to succeed.
                </p> */}

                <div className="games__promo__button -scaled -hidden">
                  <a href="https://forms.gle/6p3psuHNnWNVnNia6" target="_blank" rel="noopener noreferrer" className="games__promo__button__link">
                    <span className="games__promo__button__link__text">Contact Us</span>
                  </a>
                  <div className="games__promo__button__shadow"></div>
                </div>

                {/* <div className="main-page__hero__logos">
                  <div className="main-page__hero__logos__item -xbox"></div>
                  <div className="main-page__hero__logos__item -ps"></div>
                  <div className="main-page__hero__logos__item -nintendo"></div>
                  <div className="main-page__hero__logos__item -epic"></div>
                  <div className="main-page__hero__logos__item -unreal"></div>
                  <div className="main-page__hero__logos__item -unity"></div>
                </div> */}
              
              </div>

            </section>

          </div>


          <div className="main-content">


            {/* <section className="main-page-section">

              <div className="center">

                <h4 className="main-page-section__title">
                  <i></i>
                  <span className="main-page-section__title__text">
                    Multiply Your Potential
                  </span>
                </h4>

                <p className="main-page-section__message">
                  Super is a video games company that publishes and invests in gaming studios around the globe.
                </p>

              </div>

              <div className="center -thin">

                <div className="main-page-section__content">
                
                    <div className="multiply">
                      <div className="multiply__line">
                        <Link to="/publishing/" className="multiply__item">
                          <h5 className="multiply__item__title">
                            Full-Scale Publishing
                          </h5>
                          <p className="multiply__item__text">
                            Recieve comprehensive publishing support
                          </p>
                          <div className="multiply__item__button">View More</div>
                          <div className="multiply__item__illustration -socials"></div>
                        </Link>
                        <Link to="/fund/" className="multiply__item">
                          <h5 className="multiply__item__title">
                            Funding
                          </h5>
                          <p className="multiply__item__text">
                            Submit your project to SuperFund to receive financing
                          </p>
                          <div className="multiply__item__button">View More</div>
                          <div className="multiply__item__illustration -speedometer"></div>
                        </Link>
                      </div>
                      <div className="multiply__line">
                        <Link to="/" className="multiply__item">
                          <h5 className="multiply__item__title">
                            Fast-Track Publishing
                          </h5>
                          <p className="multiply__item__text">
                            Save yourself from rainy days, sell your game right away
                          </p>
                          <div className="multiply__item__button">View More</div>
                          <div className="multiply__item__illustration -counter"></div>
                        </Link>
                        <Link to="/" className="multiply__item">
                          <h5 className="multiply__item__title">
                            Storefront
                          </h5>
                          <p className="multiply__item__text">
                            Save yourself from rainy days, sell your game right away
                          </p>
                          <div className="multiply__item__button">View More</div>
                          <div className="multiply__item__illustration -coins"></div>
                        </Link>
                      </div>
                    </div>

                    <div className="games__promo__button -big">
                      <Link to="/" className="games__promo__button__link">
                        <span className="games__promo__button__link__text">See All Products</span>
                      </Link>
                      <div className="games__promo__button__shadow"></div>
                    </div>

                </div>

              </div>

            </section> */}


            <section className="main-page-section">

              <div className="center">

                <h4 className="main-page-section__title">
                  <i></i>
                  <span className="main-page-section__title__text">
                    Our Best Titles
                  </span>
                </h4>

              </div>

              <div className="center -thin">

                <div className="main-page-section__content">
                

                  <PortFolio></PortFolio>


                </div>

              </div>

            </section>


          </div>


          <div className="main-page">

            <section className="main-page__hero -no-overlay">

              <div className="center">

                <h2 className="main-page__hero__title -wide-letter-spacing">
                  <span className="main-page__hero__title__link">Become</span><br className="main-page__hero__title__br"/> Super Powerful
                </h2>
                <p className="main-page__hero__message -min-margin-top">
                  It doesn't take a radioactive spider or a freak accident to get Super powers.
                </p>

                <div className="games__promo__button">
                  <a href="https://forms.gle/6p3psuHNnWNVnNia6" target="_blank" rel="noopener noreferrer" className="games__promo__button__link">
                    <span className="games__promo__button__link__text">Contact Us</span>
                  </a>
                  <div className="games__promo__button__shadow"></div>
                </div>

                {/* <p className="main-page__hero__button-message">
                  Just click the button and get the resources you need to have an edge over your competition.
                </p> */}
              
              </div>

            </section>

          </div>


          {/* <div className="center">
            <LastNews number={3} sort="last" title="News &amp; Articles" link={true}></LastNews>
          </div> */}

        </Layout>

      </>
    );

  }

}

export default HomePage
