import { Link } from "gatsby"
import React from "react"

import "./portfolio.scss"

const PortFolio = () => (
  <>

    
    <div className="portfolio -desktop">

      <div className="portfolio__top">

        <div className="portfolio__top__column">

          <div className="portfolio__top__column__top">

            <Link to="/games/raji/" className="portfolio__item -raji">
              <div className="portfolio__item__info">
                  <h5 className="portfolio__item__info__title">Raji: An Ancient Epic</h5>
                  <hr className="portfolio__item__info__line"/>
                  <p className="portfolio__item__info__text">
                    Nodding Heads Games
                  </p>
                </div>
                <div className="portfolio__item__button">
                  <div className="games__card__more">
                    <div className="games__card__more__link">
                      <span className="games__card__more__link__text"><span>MORE ABOUT THE GAME</span></span>
                    </div>
                  </div>
                </div>
            </Link>

          </div>

          <div className="portfolio__top__column__bottom">

          <Link to="/games/alchemist-adventure/" className="portfolio__item -alchemist">
              <div className="portfolio__item__info">
                  <h5 className="portfolio__item__info__title">Alchemist Adventure</h5>
                  <hr className="portfolio__item__info__line"/>
                  <p className="portfolio__item__info__text">
                    Bad Minions
                  </p>
                </div>
                <div className="portfolio__item__button">
                  <div className="games__card__more">
                    <div className="games__card__more__link">
                      <span className="games__card__more__link__text"><span>MORE ABOUT THE GAME</span></span>
                    </div>
                  </div>
                </div>
            </Link>

            <Link to="/games/deflector/" className="portfolio__item -deflector">
              <div className="portfolio__item__info">
                <h5 className="portfolio__item__info__title">Deflector</h5>
                <hr className="portfolio__item__info__line"/>
                <p className="portfolio__item__info__text">
                  ARROWFIST
                </p>
              </div>
              <div className="portfolio__item__button">
                <div className="games__card__more">
                  <div className="games__card__more__link">
                    <span className="games__card__more__link__text"><span>MORE ABOUT THE GAME</span></span>
                  </div>
                </div>
              </div>
            </Link>

          </div>

        </div>

        <div className="portfolio__top__column">

          <Link to="/games/deadlink/" className="portfolio__item -deadlink">
            <div className="portfolio__item__info">
              <h5 className="portfolio__item__info__title">Deadlink</h5>
              <hr className="portfolio__item__info__line"/>
              <p className="portfolio__item__info__text">
                Gruby Entertainment
              </p>
            </div>
            <div className="portfolio__item__button">
              <div className="games__card__more">
                <div className="games__card__more__link">
                  <span className="games__card__more__link__text"><span>MORE ABOUT THE GAME</span></span>
                </div>
              </div>
            </div>
          </Link>

        </div>

      </div>


      <div className="portfolio__bottom">

        <Link to="/games/wonhon/" className="portfolio__item -wonhon">
          <div className="portfolio__item__info">
            <h5 className="portfolio__item__info__title">Wonhon</h5>
            <hr className="portfolio__item__info__line"/>
            <p className="portfolio__item__info__text">
              Busan Sanai Games
            </p>
          </div>
          <div className="portfolio__item__button">
            <div className="games__card__more">
              <div className="games__card__more__link">
                <span className="games__card__more__link__text"><span>MORE ABOUT THE GAME</span></span>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/games/retro-machina/" className="portfolio__item -retro-machina">
          <div className="portfolio__item__info">
            <h5 className="portfolio__item__info__title">Retro Machina</h5>
            <hr className="portfolio__item__info__line"/>
            <p className="portfolio__item__info__text">
              Orbit Studio
            </p>
          </div>
          <div className="portfolio__item__button">
            <div className="games__card__more">
              <div className="games__card__more__link">
                <span className="games__card__more__link__text"><span>MORE ABOUT THE GAME</span></span>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/games/tiltpack/" className="portfolio__item -tilt-pack">
          <div className="portfolio__item__info">
            <h5 className="portfolio__item__info__title">Tilt Pack</h5>
            <hr className="portfolio__item__info__line"/>
            <p className="portfolio__item__info__text">
              Navel
            </p>
          </div>
          <div className="portfolio__item__button">
            <div className="games__card__more">
              <div className="games__card__more__link">
                <span className="games__card__more__link__text"><span>MORE ABOUT THE GAME</span></span>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/games/broken-lines/" className="portfolio__item -broken-lines">
          <div className="portfolio__item__info">
            <h5 className="portfolio__item__info__title">Broken Lines</h5>
            <hr className="portfolio__item__info__line"/>
            <p className="portfolio__item__info__text">
              Porta Play
            </p>
          </div>
          <div className="portfolio__item__button">
            <div className="games__card__more">
              <div className="games__card__more__link">
                <span className="games__card__more__link__text"><span>MORE ABOUT THE GAME</span></span>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/games/metaphora/" className="portfolio__item -metaphora">
          <div className="portfolio__item__info">
            <h5 className="portfolio__item__info__title">Metaphora</h5>
            <hr className="portfolio__item__info__line"/>
            <p className="portfolio__item__info__text">
              Moonycat Entertainment
            </p>
          </div>
          <div className="portfolio__item__button">
            <div className="games__card__more">
              <div className="games__card__more__link">
                <span className="games__card__more__link__text"><span>MORE ABOUT THE GAME</span></span>
              </div>
            </div>
          </div>
        </Link>

      </div>

    </div>


    <div className="portfolio -mobile">

        <Link to="/games/deadlink/" className="portfolio__item -deadlink">
          <div className="portfolio__item__info">
            <h5 className="portfolio__item__info__title">Deadlink</h5>
            <hr className="portfolio__item__info__line"/>
            <p className="portfolio__item__info__text">
              Gruby Entertainment
            </p>
          </div>
          <div className="portfolio__item__button">
            <div className="games__card__more">
              <div className="games__card__more__link">
                <span className="games__card__more__link__text"><span>MORE ABOUT THE GAME</span></span>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/games/raji/" className="portfolio__item -raji">
            <div className="portfolio__item__info">
            <h5 className="portfolio__item__info__title">Raji: An Ancient Epic</h5>
            <hr className="portfolio__item__info__line"/>
            <p className="portfolio__item__info__text">
                Nodding Heads Games
            </p>
            </div>
            <div className="portfolio__item__button">
                <div className="games__card__more">
                    <div className="games__card__more__link">
                        <span className="games__card__more__link__text"><span>MORE ABOUT THE GAME</span></span>
                    </div>
                </div>
            </div>
        </Link>

        <Link to="/games/alchemist-adventure/" className="portfolio__item -alchemist">
            <div className="portfolio__item__info">
                <h5 className="portfolio__item__info__title">Alchemist Adventure</h5>
                <hr className="portfolio__item__info__line"/>
                <p className="portfolio__item__info__text">
                Bad Minions
                </p>
            </div>
            <div className="portfolio__item__button">
                <div className="games__card__more">
                    <div className="games__card__more__link">
                        <span className="games__card__more__link__text"><span>MORE ABOUT THE GAME</span></span>
                    </div>
                </div>
            </div>
        </Link>

        <Link to="/games/wonhon/" className="portfolio__item -wonhon">
            <div className="portfolio__item__info">
            <h5 className="portfolio__item__info__title">Wonhon</h5>
            <hr className="portfolio__item__info__line"/>
            <p className="portfolio__item__info__text">
                Busan Sanai Games
            </p>
            </div>
            <div className="portfolio__item__button">
                <div className="games__card__more">
                    <div className="games__card__more__link">
                        <span className="games__card__more__link__text"><span>MORE ABOUT THE GAME</span></span>
                    </div>
                </div>
            </div>
        </Link>

        <Link to="/games/zelter/" className="portfolio__item -zelter">
            <div className="portfolio__item__info">
            <h5 className="portfolio__item__info__title">Zelter</h5>
            <hr className="portfolio__item__info__line"/>
            <p className="portfolio__item__info__text">
                G1 Playground
            </p>
            </div>
            <div className="portfolio__item__button">
            <div className="games__card__more">
                <div className="games__card__more__link">
                <span className="games__card__more__link__text"><span>MORE ABOUT THE GAME</span></span>
                </div>
            </div>
            </div>
        </Link>

        <Link to="/games/retro-machina/" className="portfolio__item -retro-machina">
          <div className="portfolio__item__info">
            <h5 className="portfolio__item__info__title">Retro Machina</h5>
            <hr className="portfolio__item__info__line"/>
            <p className="portfolio__item__info__text">
              Orbit Studio
            </p>
          </div>
          <div className="portfolio__item__button">
            <div className="games__card__more">
              <div className="games__card__more__link">
                <span className="games__card__more__link__text"><span>MORE ABOUT THE GAME</span></span>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/games/tiltpack/" className="portfolio__item -tilt-pack">
          <div className="portfolio__item__info">
            <h5 className="portfolio__item__info__title">Tilt Pack</h5>
            <hr className="portfolio__item__info__line"/>
            <p className="portfolio__item__info__text">
              Navel
            </p>
          </div>
          <div className="portfolio__item__button">
            <div className="games__card__more">
              <div className="games__card__more__link">
                <span className="games__card__more__link__text"><span>MORE ABOUT THE GAME</span></span>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/games/broken-lines/" className="portfolio__item -broken-lines">
          <div className="portfolio__item__info">
            <h5 className="portfolio__item__info__title">Broken Lines</h5>
            <hr className="portfolio__item__info__line"/>
            <p className="portfolio__item__info__text">
              Porta Play
            </p>
          </div>
          <div className="portfolio__item__button">
            <div className="games__card__more">
              <div className="games__card__more__link">
                <span className="games__card__more__link__text"><span>MORE ABOUT THE GAME</span></span>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/games/metaphora/" className="portfolio__item -metaphora">
          <div className="portfolio__item__info">
            <h5 className="portfolio__item__info__title">Metaphora</h5>
            <hr className="portfolio__item__info__line"/>
            <p className="portfolio__item__info__text">
              Moonycat Entertainment
            </p>
          </div>
          <div className="portfolio__item__button">
            <div className="games__card__more">
              <div className="games__card__more__link">
                <span className="games__card__more__link__text"><span>MORE ABOUT THE GAME</span></span>
              </div>
            </div>
          </div>
        </Link>

    </div>


  </>
)

export default PortFolio
